import React from 'react';
import { Helmet } from "react-helmet";
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import TemplateWrapper from '../components/layout';

const mapState = { center: [55.697986, 37.560426], zoom: 14, small:true };

const Salon = () => (
  <TemplateWrapper>
<div className="article-content">
<Helmet>
  <title>Как нас найти</title>
  <meta name="description" content="Адрес салона hair.moscow" />
  <meta name="keywords" content="Адрес салона, ленинский, наращивание на ленинском" />
</Helmet>

  <h1 className="hm-title-1">Мы временно закрыты :(</h1>
  {/* Ленинский проспект 54, универмаг "Москва", этаж 1а:
  <h2>+7 910 457-99-08</h2>
  <h2>+7 985 266-65-21</h2>
  <h2>+7 917 528-26-03</h2>
  <br /><br /> */}
    
  <YMaps>
    <Map state={mapState}>
      <Placemark
        geometry={{
          coordinates: [55.697986, 37.560426], 
        }}
        properties={{
          hintContent: 'Hair.moscow',
          balloonContent: 'Это красивая метка'
        }}
        options={{
         
          iconImageSize: [36, 36],
          iconImageOffset: [0, 0]
        }}
      />
    </Map>
  </YMaps>

  <br/><br/>  
  <img src="/img/photos/salon/1.jpg" className="img-fluid" alt="Маршрут к нашему салону" width="800" />
  {/* <br /><br/>
  <img src="/img/photos/salon/2.jpg" width="800" /> */}
  <br /><br/>
  <img src="/img/photos/salon/3.jpg" className="img-fluid" alt="Маршрут к нашему салону" width="800" />
  <br /><br/>
    <img src="/img/photos/salon/4.jpg" className="img-fluid" alt="Маршрут к нашему салону" width="800" />
  <br/>  
</div>
</TemplateWrapper>
)

export default Salon